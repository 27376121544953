import { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { isProfileStarred } from "selectors";
import { styled } from '@mui/material/styles';
import { useTranslation } from "react-i18next";

const StyledProfileImage = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
});

const StyledImage = styled('img')({
  width: 236,
  height: 236,
  borderRadius: 236,
});

const StyledMatch = styled('div')({
  border: '4px solid #4D84FF',
  width: '236px',
  height: '236px',
  borderRadius: '236px',
  top: '-4px',
  position: 'absolute',
  background: 'transparent linear-gradient(180deg, #18228200 0%, #0A0D54 100%) 0% 0% no-repeat padding-box',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  alignItems: 'center',
});

const StyledWrapper = styled('div')({
  marginBottom: '55px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledText1 = styled('div')({
  font: 'italic normal bold 20px/30px Poppins',
});

const StyledText2 = styled('div')({
  font: 'italic normal 800 30px/46px Poppins',
  color: '#50E3C2',
  letterSpacing: '5.5px',
});

type ProfileImageProps = {
    imageSrc: string | undefined;
}

export const ProfileImage: FunctionComponent<ProfileImageProps> = ({
    imageSrc
}) => {
    const isStarred = useSelector(isProfileStarred);
    const { t } = useTranslation();

    return (
    <StyledProfileImage>
      {isStarred && <StyledMatch>
        <StyledWrapper>
          <StyledText1>{t('itsA')}</StyledText1>
          <StyledText2>{t('match').toUpperCase()}</StyledText2>
        </StyledWrapper>
      </StyledMatch>}
      <StyledImage src={imageSrc ?? "/images/default-user-image.svg"} />
    </StyledProfileImage>
    );
}