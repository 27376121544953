import { ADD_SEARCH_PROFILE, ADD_STARRED_PROFILE, ADD_PROFILE_STATE, ProfileState } from "../constants";
import { AnyAction } from "redux"
import { call, put, takeEvery } from "redux-saga/effects";
import { IndexedDB } from "utils";
import { createSetStarredProfileAction, createSetProfileStateAction, createSetStoreDataAction } from "actions";

export const storeDataSaga = function* () {
  yield takeEvery(ADD_SEARCH_PROFILE, addSearchProfile);
  yield takeEvery(ADD_STARRED_PROFILE, addStarredProfile);
  yield takeEvery(ADD_PROFILE_STATE, updateProfileState);
}

const addSearchProfile = function* (action: AnyAction): any {
  try {
    const profile = yield IndexedDB.addOrUpdateSearchProfile(action.payload.username);
    yield put(createSetStoreDataAction(profile));
  } catch(error) {
    console.log(error);
  }
}

const addStarredProfile = function* (action: AnyAction): any {
  try {
    yield IndexedDB.addOrUpdateStarredProfile(action.payload.username, action.payload.isStarred);
    yield put(createSetStarredProfileAction(action.payload.username, action.payload.isStarred));
    if (!action.payload.isStarred) {
      yield call(() => updateProfileState({ 
        type: ADD_PROFILE_STATE, 
        payload: { 
          username: action.payload.username, 
          state: ProfileState.match,
        },
      }));
    }
  } catch(error) {
    console.log(error);
  }
}

const updateProfileState = function* (action: AnyAction): any {
  try {
    yield IndexedDB.updateProfileState(action.payload.username, action.payload.state);
    yield put(createSetProfileStateAction(action.payload.username, action.payload.state));
  } catch(error) {
    console.log(error);
  }
}
