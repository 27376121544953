import { FunctionComponent } from "react";
import { Button as MaterialButton, ButtonProps as MaterialButtonProps } from '@material-ui/core';
import { styled } from '@mui/material/styles';

type ButtonProps = {
    width?: number | string,
    backgroundColor?: string,
    borderColor?: string,
    target?: "_blank",
} & MaterialButtonProps;

const StyledButton = styled((props: ButtonProps) => (
    <MaterialButton {...props} />
))`
    width: ${props => props.width};
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'transparent')};
    border-radius: ${props => (props.variant === "contained" ? "4px" : "24px")};
    border: 1px solid ${props => (props.borderColor ? props.borderColor : 'transparent')};
    color: #C9D1D9;
    font-size: 14px;
    text-transform: none;
    box-shadow: none;

    &:hover {
        background-color: ${props => (props.backgroundColor ? props.backgroundColor : 'transparent')};
        box-shadow: none;
    }
`

export const Button: FunctionComponent<ButtonProps> = ({
    children,
    href,
    onClick,
    target,
    ...props
}) => {
    const onClickHandler = onClick || (() => {
      window.open(href, target);
    });

    return(
        <StyledButton onClick={onClickHandler} {...props}>{children}</StyledButton>
    );
}