import { styled } from '@mui/material/styles';
import React from 'react';

const StyledLoader = styled((props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & LoaderProps) => (
  <div {...props} />
))`
  border: ${props => props.variant === "small" ? "4px" : "8px"} solid #00000000;
  border-radius: 50%;
  border-top: ${props => props.variant === "small" ? "4px" : "8px"} solid #355AA7;
  border-bottom: ${props => props.variant === "small" ? "4px" : "8px"} solid #355AA7;
  width: ${props => {
    switch(props.variant) {
      case "small": return "20px";
      case "medium": return "50px";
      case "large": return "90px";
    }
  }};
  height: ${props => {
    switch(props.variant) {
      case "small": return "20px";
      case "medium": return "50px";
      case "large": return "90px";
    }
  }};
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
      
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

export type LoaderProps = {
  variant: "small" | "medium" | "large",
}

export const Loader: React.FC<LoaderProps> = ({
  variant = "medium",
}) => (<StyledLoader {...{ variant }} />);
