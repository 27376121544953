import { Language, Organization, Profile, ReposInfo, RoleInfo, RootState, SocialNetwork, Status, ThesisDto } from "types";

export const getUserProfile = (state: RootState): Profile | undefined => 
  state.userDetailData.userProfile.userProfile;

export const getUsername = (state: RootState): string | undefined => 
  state.userDetailData.userProfile.userProfile?.username;

export const getDSpaceTheses = (state: RootState): ThesisDto[] | undefined =>
  state.userDetailData.dSpaceTheses.dSpaceTheses;

export const getDSpaceThesesStatus = (state: RootState): Status => 
  state.userDetailData.dSpaceTheses.status;

export const getUserDetailStatus = (state: RootState): Status =>
  state.userDetailData.status;

export const getOrganizations = (state: RootState): Organization[] | undefined => 
  state.userDetailData.organizations.organizations;

export const getSocialNetworks = (state: RootState): SocialNetwork[] | undefined =>
  state.userDetailData.socialNetworks.socialNetworks;

export const getRoles = (state: RootState): RoleInfo[] | undefined =>
  state.userDetailData.repositoriesData.roles;

export const getRepositoriesDataStatus = (state: RootState): Status =>
  state.userDetailData.repositoriesData.status;

export const getTopFiveLanguages = (state: RootState): Language[] | undefined =>
  state.userDetailData.repositoriesData.languages?.slice(0, 5);

export const getReposInfo = (state: RootState): ReposInfo | undefined =>
  state.userDetailData.repositoriesData.reposInfo;

export const getEmails = (state: RootState): string[] | undefined =>
  state.userDetailData.repositoriesData.emails;
