import { FunctionComponent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { Input, InputProps } from "@material-ui/core";
import { KeyboardKeyCodes, useKeyboadListener } from "../../hooks";
import { InputAdornment } from "@material-ui/core";
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { createSetUserDetailRequestingAction } from "actions";

type StyledInputProps = {
  isOpen: boolean
} & InputProps;

const StyledInput = styled(Input, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})
<StyledInputProps>(({ theme, isOpen }) => 
({
  borderRadius: 50,
  border: `1px solid ${theme.color.border}`,
  width: isOpen ? 200 : 36,
  height: 36,
  input: {
    color: theme.color.text,
    paddingLeft: isOpen ? 16 : 7
  },
}));

const StyledSearchIcon = styled(SearchIcon)({
  width: 20,
  cursor: "pointer",
});

const StyledInputAdornment = styled(InputAdornment)({
  cursor: 'pointer',
});

export const SearchBar: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const search = () => dispatch(createSetUserDetailRequestingAction(searchText));
  useKeyboadListener(search, KeyboardKeyCodes.enter, ref);

  const onClick = () => {
    if(isOpen) {
      search();
    }
    else {
      setIsOpen(!isOpen);
      ref.current?.querySelector('input')?.focus();
    }
  }

  return (
    <StyledInput
      disableUnderline
      onChange={(e) => setSearchText(e.currentTarget.value)}
      {...{ isOpen, ref }}
      endAdornment={
        <InputAdornment position="start">
          <StyledSearchIcon
            color={"border"}
            onClick={onClick} />
          </InputAdornment>
      }
    />);
};
