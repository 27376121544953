import { FunctionComponent } from "react";
import styles from "./ProfilePanel.module.scss";
import { ProfileImage } from "../ProfileImage";
import { FollowPanel } from "../FollowPanel";
import { Text } from "../TextWithIcon";
import { Button } from "../Button";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Organization } from "components";
import { styled, Typography } from "@material-ui/core";
import { StarredButton } from "components/StarredButton";
import { Loader } from "components/Loader";
import { getEmails, getOrganizations, getUserDetailStatus, getUserProfile } from "selectors";
import { Status } from "types";

const StyledName = styled(Typography)({
  color: "#C9D1D9",
});

const StyledUsername = styled(Typography)({
  color: "#808892",
});

const StyledBio = styled(Typography)({
  color: "#E1E3E6", 
  fontWeight: 100,
});

const StyledEmails = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 5,
  fontWeight: 300,
});

const StyledButtons = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const StyledLoaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const ProfilePanel: FunctionComponent = () => {
  const { t } = useTranslation();
  const profile = useSelector(getUserProfile);
  const emails = useSelector(getEmails);
  const ogranizations = useSelector(getOrganizations);
  const status = useSelector(getUserDetailStatus);

  return (
    <div className={styles.container}>
      <ProfileImage imageSrc={profile?.profileImageUrl} />
      <div>
        <StyledName variant="h5">{profile?.name}</StyledName>
        {profile?.username && <StyledUsername variant="body1">@{profile?.username}</StyledUsername>}
      </div>
      <StyledButtons>
        <Button href={profile?.githubUrl} target="_blank" backgroundColor="#4D84FF" variant="contained" width="172px">{t("openGithubProfile")}</Button>
        <StarredButton />
      </StyledButtons>
      <StyledBio variant="body1">{profile?.bio}</StyledBio>
      <FollowPanel followers={profile?.followers} following={profile?.following} stars={profile?.starredRepos} />
      <div className={styles.location}>
        {profile?.company && <Text iconName="house" text={profile?.company} bold />}
        {profile?.location && <Text iconName="location" text={profile?.location} />}
        {profile?.blog && <Text iconName="link" text={profile?.blog} link={profile?.blog} />}
      </div>
      <StyledEmails>
        {!emails && status === Status.requesting && 
        <StyledLoaderWrapper>
          <Loader variant="medium" />
        </StyledLoaderWrapper>}
        {emails?.map((email: string, index: number) => (
          <a key={index} href={`mailto:${email}`}>
            {email}
          </a>
        ))}
      </StyledEmails>
      {ogranizations?.map(o => 
        <Organization imageSrc={o.imageSrc} description={o.description} title={o.title} />
      )}
    </div>
  );
};
