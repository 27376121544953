import styled from "@emotion/styled";
import { MenuItem, Select, Slider } from "@material-ui/core";
import { Card, StyledCardContent } from "components/Card";
import { ProfileState } from "../../constants";
import { ChangeEvent, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createAddProfileStateAction } from "actions";
import { getProfileState, getUsername } from "../../selectors";

const STATE_SLIDER_DEFAULT_VALUE = ProfileState.match + 0.15;

const StyledSlider = styled(Slider)({
  '& .MuiSlider-rail': {
    height: '8px',
    borderRadius: '12px',
  },
  '& .MuiSlider-mark': {
    display: 'none',
  },
  '& .MuiSlider-track': {
    height: '8px',
    borderRadius: '12px',
    width: '30px',
    background: 'transparent linear-gradient(90deg, #624DFF 0%, #568FDE 100%) 0% 0% no-repeat padding-box',
  },
  '& .MuiSlider-thumb': {
    display: 'none',
  },
  '&.Mui-disabled': {
    color: '#192A45',
  },
});

const StyledMark = styled('div')<{ variant?: 'left' | 'right' }>(({ variant }) => ({
  color: '#9EA3A8',
  marginTop: 6,
  paddingLeft: variant === 'left' ? 40 : 0,
  paddingRight: variant === 'right' ? 60 : 0,
}));

const StyledSelect = styled(Select)({
  border: '1px solid #2D3643',
  borderRadius: 4,
  paddingLeft: 12,
  width: 160,
  color: 'white',
  '& .MuiSelect-icon': {
    color: 'white',
  },
  '& .MuiSelect-select': {
    color: 'white',
  },
  '& .MuiSelect-nativeInput': {
    color: 'white'
  },
  '& 	.MuiSelect-standard': {
    color: 'white'
  }
});

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: '#12171F',
  color: 'white',
  '&:hover': {
    backgroundColor: '#171E27',
  },
  '&.Mui-selected': {
    backgroundColor: '#12171F'
  },
  '&.Mui-selected:hover': {
    backgroundColor: '#171E27',
  }
})

const StyledPaper = styled('div')({
  backgroundColor: '#12171F',
  width: '158px',
  border: '1px solid #2D3643',
  '& .MuiMenu-list': {
    padding: '4px 0px',
  }
})

export const StateCard: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const username = useSelector(getUsername);
  const profileState = useSelector(getProfileState);

  const marks = [
    {
      value: ProfileState.match,
      label: <StyledMark variant="left">{t(ProfileState[ProfileState.match])}</StyledMark>,
      text: t(ProfileState[ProfileState.match]),
    },
    {
      value: ProfileState.withoutReaction,
      label: <StyledMark>{t(ProfileState[ProfileState.withoutReaction])}</StyledMark>,
      text: t(ProfileState[ProfileState.withoutReaction]),
    },
    {
      value: ProfileState.unconcerned,
      label: <StyledMark>{t(ProfileState[ProfileState.unconcerned])}</StyledMark>,
      text: t(ProfileState[ProfileState.unconcerned]),
    },
    {
      value: ProfileState.interest,
      label: <StyledMark variant="right">{t(ProfileState[ProfileState.interest])}</StyledMark>,
      text: t(ProfileState[ProfileState.interest]),
    }
  ];

  const getSliderValue = (value: ProfileState | undefined) => value === ProfileState.match ? STATE_SLIDER_DEFAULT_VALUE : value;

  const onChangeState = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
    if (username) {
      dispatch(createAddProfileStateAction(username, event.target.value as number));
    }
  };

  const Select =
    <StyledSelect
      value={profileState}
      disableUnderline
      variant="standard"
      onChange={onChangeState}
      MenuProps={{
        PaperProps: {
          component: StyledPaper
        }
      }}
    >
      {marks.map(mark => <StyledMenuItem value={mark.value}>{mark.text}</StyledMenuItem>)}
    </StyledSelect>

  return (
    <Card title={"State"} headerComponents={{
      right: Select,
    }}>
      <StyledCardContent>
        <StyledSlider
          {...{ marks }}
          defaultValue={STATE_SLIDER_DEFAULT_VALUE}
          value={getSliderValue(profileState)}
          step={1}
          min={1}
          max={4}
          disabled
        />
      </StyledCardContent>
    </Card>
  );
}