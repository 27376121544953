import { FunctionComponent } from "react";
import styles from './OverviewCard.module.scss';
import {Text} from '../Text';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { getReposInfo, getUserProfile } from "../../selectors";
import { Card, StyledCardContent } from "components/Card";
import { styled } from "@material-ui/core";

const StyledCard = styled(Card)({
  height: 200,
});

export const OverviewCard: FunctionComponent = () => {
    const { t } = useTranslation();
    const profile  = useSelector(getUserProfile);
    const reposInfo = useSelector(getReposInfo);
    const createdAt = profile ? new Date(profile.createdAt).getFullYear().toString() : undefined;
    const updatedAt = profile ? new Date(profile.updatedAt).toLocaleDateString() : undefined;

    return(
      <StyledCard 
        title={t("overview")} 
        hasData={!!createdAt || !!updatedAt || !!reposInfo}
        isLoading={!createdAt || !updatedAt}
      >
        <StyledCardContent>
          <div className={styles.container}>
            <Text type="bigWithLabel" label={t("Joined")} isLoading={!createdAt} value={createdAt}/>
            <Text type="bigWithLabel" label={t("lastUpdate")} isLoading={!updatedAt} value={updatedAt}/>
            <Text type="bigWithLabel" label={t("Forks")} isLoading={!reposInfo} value={reposInfo?.totalForks.toString()} />
            <Text type="bigWithLabel" label={t("starsOnRepos")} isLoading={!reposInfo} value={reposInfo?.totalStargazers.toString()} />
          </div>
        </StyledCardContent>
      </StyledCard>
    );
}