import { 
  SET_DSPACE_THESES_ERROR,
  SET_DSPACE_THESES_INIT,
  SET_DSPACE_THESES_REQUESTING,
  SET_DSPACE_THESES_SUCCESS,
  SET_ORGANIZATIONS_ERROR,
  SET_ORGANIZATIONS_INIT,
  SET_ORGANIZATIONS_REQUESTING,
  SET_ORGANIZATIONS_SUCCESS,
  SET_REPOSITORIES_DATA_ERROR,
  SET_REPOSITORIES_DATA_INI,
  SET_REPOSITORIES_DATA_REQUESTING,
  SET_REPOSITORIES_DATA_SUCCESS,
  SET_SOCIAL_NETWORKS_ERROR,
  SET_SOCIAL_NETWORKS_INIT,
  SET_SOCIAL_NETWORKS_REQUESTING,
  SET_SOCIAL_NETWORKS_SUCCESS,
  SET_STARRED_REPOS_ERROR,
  SET_STARRED_REPOS_INIT,
  SET_STARRED_REPOS_REQUESTING,
  SET_STARRED_REPOS_SUCCESS,
  SET_USER_PROFILE_ERROR,
  SET_USER_PROFILE_INIT,
  SET_USER_PROFILE_REQUESTING,
  SET_USER_PROFILE_SUCCESS
} from "../constants";
import produce from "immer";
import { IDSpaceThesesState, IOrganizationsState, IRepositoriesDataState, ISocialNetworkState, IStarredReposState, IUserDetailDataState, IUserProfileState, Status } from "types";
import { combineReducers } from "redux";

export const initUserProfileState: IUserProfileState = {
  status: Status.idle,
  errorMsg: undefined,
  userProfile: undefined,
};

const initOrganizationsState: IOrganizationsState = {
  organizations: undefined,
  status: Status.idle,
  errorMsg: undefined,
};

const initRepositoriesDataState: IRepositoriesDataState = {
  status: Status.idle,
  errorMsg: undefined,
  languages: undefined,
  emails: undefined,
  reposInfo: undefined,
  roles: undefined,
};

const initStarredReposState: IStarredReposState = {
  status: Status.idle,
  starredRepos: undefined,
  errorMsg: undefined,
}

const initSocialNetworksState: ISocialNetworkState = {
  status: Status.idle,
  socialNetworks: undefined,
  errorMsg: undefined,
}

const initDspaceThesesState: IDSpaceThesesState = {
  status: Status.idle,
  dSpaceTheses: undefined,
  errorMsg: undefined,
}

const userProfileReducer = produce((draft, action) => {
  switch(action.type) {
    case SET_USER_PROFILE_INIT: {
      draft = initUserProfileState;
      break;
    }
    case SET_USER_PROFILE_REQUESTING: {
      draft.status = Status.requesting;
      draft.errorMsg = undefined;
      draft.userProfile = undefined;
      break;
    }
    case SET_USER_PROFILE_SUCCESS: {
      draft.status = Status.success;
      draft.errorMsg = undefined;
      draft.userProfile = action.payload.userProfile;
      break;
    }
    case SET_USER_PROFILE_ERROR: {
      draft.status = Status.error;
      draft.errorMsg = action.payload.errorMsg;
      draft.userProfile = undefined;
      break;
    }
  }
}, initUserProfileState);

const oganizationsReducer = produce((draft, action) => {
  switch(action.type) {
    case SET_ORGANIZATIONS_INIT: {
      draft = initOrganizationsState;
      break;
    }
    case SET_ORGANIZATIONS_REQUESTING: {
      draft.errorMsg = undefined;
      draft.organizations = undefined;
      draft.status = Status.requesting;
      break;
    }
    case SET_ORGANIZATIONS_SUCCESS: {
      draft.errorMsg = undefined;
      draft.organizations = action.payload.organizations;
      draft.status = Status.success;
      break;
    }
    case SET_ORGANIZATIONS_ERROR: {
      draft.organizations = undefined;
      draft.status = Status.error;
      draft.errorMsg = action.payload.errorMsg;
    }
  }
}, initOrganizationsState);

const repositoriesDataReducer = produce((draft, action) => {
  switch(action.type) {
    case SET_REPOSITORIES_DATA_INI: {
      draft = initRepositoriesDataState;
      break;
    }
    case SET_REPOSITORIES_DATA_REQUESTING: {
      draft.emails = undefined;
      draft.languages = undefined;
      draft.reposInfo = undefined;
      draft.roles = undefined;
      draft.status = Status.requesting;
      draft.errorMsg = undefined;
      break;
    }
    case SET_REPOSITORIES_DATA_SUCCESS: {
      draft.emails = action.payload.emails;
      draft.languages = action.payload.languages;
      draft.reposInfo = action.payload.reposInfo;
      draft.roles = action.payload.roles;
      draft.status = Status.success;
      draft.errorMsg = undefined;
      break;
    }
    case SET_REPOSITORIES_DATA_ERROR: {
      draft.emails = undefined;
      draft.languages = undefined;
      draft.reposInfo = undefined;
      draft.roles = undefined;
      draft.status = Status.error;
      draft.errorMsg = action.payload.errorMsg;
    }
  }
}, initRepositoriesDataState);

const starredReposReducer = produce((draft, action) => {
  switch(action.type) {
    case SET_STARRED_REPOS_INIT: {
      draft = initStarredReposState;
      break;
    }
    case SET_STARRED_REPOS_REQUESTING: {
      draft.status = Status.requesting;
      draft.starredRepos = undefined;
      draft.errorMsg = undefined;
      break;
    }
    case SET_STARRED_REPOS_SUCCESS: {
      draft.status = Status.success;
      draft.starredRepos = action.payload.starredRepos;
      draft.errorMsg = undefined;
      break;
    }
    case SET_STARRED_REPOS_ERROR: {
      draft.status = Status.error;
      draft.errorMsg = action.payload.errorMsg;
      draft.starredRepos = undefined;
      break;
    }
  }
}, initStarredReposState);

const SocialNetworksReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_SOCIAL_NETWORKS_INIT: {
      draft = initSocialNetworksState;
      break;
    }
    case SET_SOCIAL_NETWORKS_REQUESTING: {
      draft.status = Status.requesting;
      draft.errorMsg = undefined;
      draft.socialNetworks = undefined;
      break;
    }
    case SET_SOCIAL_NETWORKS_SUCCESS: {
      draft.status = Status.success;
      draft.errorMsg = undefined;
      draft.socialNetworks = action.payload.socialNetworkUrls;
      break;
    }
    case SET_SOCIAL_NETWORKS_ERROR: {
      draft.status = Status.requesting;
      draft.socialNetworks = undefined;
      draft.errorMsg = action.payload.errorMsg;
      break;
    }
  }
}, initSocialNetworksState);

export const dSpaceThesesReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_DSPACE_THESES_INIT: {
      draft = initDspaceThesesState;
      break;
    }
    case SET_DSPACE_THESES_REQUESTING: {
      draft.status = Status.requesting;
      draft.dSpaceTheses = undefined;
      draft.errorMsg = undefined;
      break;
    }
    case SET_DSPACE_THESES_SUCCESS: {
      draft.status = Status.success;
      draft.dSpaceTheses = action.payload.dSpaceTheses;
      draft.errorMsg = undefined;
      break;
    }
    case SET_DSPACE_THESES_ERROR: {
      draft.status = Status.error;
      draft.dSpaceTheses = undefined;
      draft.errorMsg = action.payload.errorMsg;
    }
  }
}, initDspaceThesesState);

export const userDetailStatusReducer = produce((draft, action) => {
  switch (action.type) {
    case SET_USER_PROFILE_REQUESTING:
    case SET_ORGANIZATIONS_REQUESTING:
    case SET_REPOSITORIES_DATA_REQUESTING:
    case SET_STARRED_REPOS_REQUESTING:
    case SET_SOCIAL_NETWORKS_REQUESTING: 
    case SET_DSPACE_THESES_REQUESTING: {
      draft = Status.requesting;
      break;
    }
    case SET_USER_PROFILE_SUCCESS:
    case SET_ORGANIZATIONS_SUCCESS:
    case SET_REPOSITORIES_DATA_SUCCESS:
    case SET_STARRED_REPOS_SUCCESS:
    case SET_SOCIAL_NETWORKS_SUCCESS:
    case SET_DSPACE_THESES_SUCCESS: {
      draft = Status.success;
      break;
    }
  }
  return draft;
}, Status.idle);

export const userDetailReducer = combineReducers<IUserDetailDataState>({
  status: userDetailStatusReducer,
  userProfile: userProfileReducer,
  organizations: oganizationsReducer,
  repositoriesData: repositoriesDataReducer,
  starredRepos: starredReposReducer,
  socialNetworks: SocialNetworksReducer,
  dSpaceTheses: dSpaceThesesReducer,
});
