import { FunctionComponent } from 'react';
import { HomePage } from '../../containers/HomePage';
import { UserDetailPage } from '../../containers/UserDetailPage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProfileNotFoundPage } from 'containers/ProfileNotFoundPage';
import { IndexedDB } from 'utils';

export const App: FunctionComponent = () => {
  IndexedDB.init();
  
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path='/user-detail' element={<UserDetailPage />} />
        <Route path="/profile-not-found" element={<ProfileNotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}