import { StoreProfile } from "types";
import { 
  ADD_PROFILE_STATE, 
  ADD_STARRED_PROFILE, 
  SET_PROFILE_STATE, 
  SET_STARRED_PROFILE, 
  SET_STORE_PROFILE_DATA,
  ADD_SEARCH_PROFILE,
  ProfileState,
} from "../constants";

export const createAddSearchProfile = (username: string) => ({
  type: ADD_SEARCH_PROFILE,
  payload: {
    username,
  }
});

export const createSetStoreDataAction = (storeData: StoreProfile) => ({
  type: SET_STORE_PROFILE_DATA,
  payload: storeData,
});

export const createSetStarredProfileAction = (username: string, isStarred: boolean) => ({
  type: SET_STARRED_PROFILE,
  payload: {
    username,
    isStarred,
  },
});

export const createSetProfileStateAction = (username: string, state: ProfileState) => ({
  type: SET_PROFILE_STATE,
  payload: {
    username,
    state,
  },
});

export const createAddStarredProfileAction = (username: string, isStarred: boolean) => ({
  type: ADD_STARRED_PROFILE,
  payload: {
    username,
    isStarred,
  },
});

export const createAddProfileStateAction = (username: string, state: ProfileState) => ({
  type: ADD_PROFILE_STATE,
  payload: {
    username,
    state,
  },
});
