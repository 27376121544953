import { FunctionComponent, ReactNode } from "react";
import styles from './card.module.scss';
import { NoDataBanner } from "components/NoDataBanner";
import { styled } from "@material-ui/core";
import { Loader } from "components/Loader";

const StyledTextWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const StyledLeftHeaderWrapper = styled('div')({
  display: 'flex',
  gap: 6,
});

const StyledRightHeaderWrapper = styled('div')({
  display: 'flex',
  gap: 6,
});

const StyledHeaderWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const StyledLoaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

export const StyledCardContent = styled('div')({
  height: '100%',
  marginTop: 30,
});

type CardProps = {
    title?: string,
    subtitle?: string,
    children: ReactNode,
    minWidth?: number,
    className?: string,
    hasData?: boolean,
    headerComponents?: {
      right?: React.ReactElement,
      left?: React.ReactElement,
    },
    isLoading?: boolean,
}

export const Card: FunctionComponent<CardProps> = ({
    title,
    subtitle,
    children,
    minWidth,
    className,
    hasData = true,
    headerComponents,
    isLoading = false,
}) => {
    return (
        <div className={styles.container + " " + className} style={{ minWidth: minWidth }}>
          <StyledHeaderWrapper>
            <StyledLeftHeaderWrapper>
              <StyledTextWrapper>
                {title && <div className={styles.header}>{title}</div>}
                {subtitle && <div className={styles.subheader}>{subtitle}</div>}
              </StyledTextWrapper>
              {headerComponents?.left}
            </StyledLeftHeaderWrapper>
            <StyledRightHeaderWrapper>
              {headerComponents?.right}
            </StyledRightHeaderWrapper>
          </StyledHeaderWrapper>
          {isLoading && <StyledLoaderWrapper>
            <Loader variant="medium" />
          </StyledLoaderWrapper>}
          {!isLoading && ((!children || !hasData) ? <NoDataBanner width={72} height={60} /> : children)}
        </div>
    );
}