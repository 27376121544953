import { styled } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components";
import { NoDataBanner } from "components/NoDataBanner";
import { createSetUserDetailInitAction } from "actions";

const StyledContent = styled("div")({
    display: "flex",
    flexDirection: "column",
    gap: 40,
    alignItems: "center",
});

const StyledContainer = styled("div")({
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#171E27",
    fontSize: 24,
});

export const ProfileNotFoundPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onTryNewSearch = () => {
        dispatch(createSetUserDetailInitAction());
        navigate("/");
    }

    return(
        <StyledContainer>
            <StyledContent>
                <NoDataBanner />
                <Button onClick={onTryNewSearch} variant="contained">{t("TryNewSearch")}</Button>
            </StyledContent>
        </StyledContainer>
    );
}