import {
  InputAdornment,
  TextField as MuiTextField,
  withStyles,
  IconButton,
  styled,
} from "@material-ui/core";
import { Fragment, FunctionComponent } from "react";
import { Icon, IconType } from "../Icon";
import "./TextInput.scss";

export interface TextInputProps {
  error?: boolean;
  helperText?: string | null;
  required?: boolean;
  label?: string;
  name?: string;
  type?: string;
  value?: string;
  onChange?: (e: any) => void | undefined;
  onKeyDown?: (e: any) => void | undefined;
  onSearch?: (e: any) => void | undefined;
  autoComplete?: string;
  icon?: string;
  placeHolder?: string;
  iconPosition?: "start" | "end";
  iconType?: IconType;
  size?: "normal" | "small";
  variant?: "primary" | "secondary";
}

const NormalTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#B0BBCB",
      },
    },
  },
})(MuiTextField);

const SmallTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#B0BBCB",
      },
      "& .MuiOutlinedInput-input": {
        padding: "13px 0px",
        color: "gray"
      },
    },
  },
})(MuiTextField);

const StyledTextField = styled(MuiTextField)({
  "& .MuiOutlinedInput-root": {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: 'transparent',
      border: '0px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'transparent',
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      backgroundColor: '#545F73',
      padding: '15px 16px',
      color: 'white',
      borderColor: 'transparent',
      '&::placeholder': {
        color: '#E1E3E659',
      },
      '& fieldset': {
        borderColor: 'transparent',
      }
    },
  }
});

export const TextInput: FunctionComponent<TextInputProps> = ({
  error,
  helperText,
  required,
  label,
  name,
  type,
  value,
  onChange,
  onKeyDown,
  onSearch,
  autoComplete,
  icon,
  placeHolder,
  iconPosition = "start",
  iconType = IconType.grey,
  size = "small",
  variant = "primary",
}) => {
  return (
    <Fragment>
      <StyledTextField
            className="text-field"
            error={error}
            helperText={helperText}
            required={required}
            label={variant === "secondary" ? label : ""}
            placeholder={placeHolder}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            autoComplete={autoComplete}
            variant="outlined"
            fullWidth={true}
            InputProps={{
              style: { fontSize: 14 },
              startAdornment:
                icon && iconPosition === "start" ? (
                  <InputAdornment position={iconPosition}>
                    {onclick === null && (
                      <Icon size="14px" name={icon} type={iconType} />
                    )}
                    {onclick !== null && (
                      <IconButton onClick={onSearch}>
                        <Icon size="14px" name={icon} type={iconType} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ) : null,
              endAdornment:
                icon && iconPosition === "end" ? (
                  <InputAdornment position={iconPosition}>
                    {onclick === null && (
                      <Icon size="14px" name={icon} type={iconType} />
                    )}
                    {onclick !== null && (
                      <IconButton onClick={onSearch}>
                        <Icon size="14px" name={icon} type={iconType} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ) : null,
            }}
          />
      {/* {size === "normal" && (
        <>
          {variant === "primary" && (
            <label className="TextInput__label">{label}</label>
          )}
          <NormalTextField
            className="text-field"
            error={error}
            helperText={helperText}
            required={required}
            label={variant === "secondary" ? label : ""}
            placeholder={placeHolder}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            autoComplete={autoComplete}
            variant="outlined"
            fullWidth={true}
            InputProps={{
              style: { fontSize: 14 },
              startAdornment:
                icon && iconPosition === "start" ? (
                  <InputAdornment position={iconPosition}>
                    {onclick === null && (
                      <Icon size="14px" name={icon} type={iconType} />
                    )}
                    {onclick !== null && (
                      <IconButton onClick={onSearch}>
                        <Icon size="14px" name={icon} type={iconType} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ) : null,
              endAdornment:
                icon && iconPosition === "end" ? (
                  <InputAdornment position={iconPosition}>
                    {onclick === null && (
                      <Icon size="14px" name={icon} type={iconType} />
                    )}
                    {onclick !== null && (
                      <IconButton onClick={onSearch}>
                        <Icon size="14px" name={icon} type={iconType} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ) : null,
            }}
          />
        </>
      )}

      {size === "small" && (
        <>
          {variant === "primary" && (
            <label className="TextInput__label">{label}</label>
          )}
          <SmallTextField
            className="text-field"
            error={error}
            helperText={helperText}
            required={required}
            label={variant === "secondary" ? label : ""}
            placeholder={placeHolder}
            name={name}
            type={type}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            autoComplete={autoComplete}
            variant="outlined"
            fullWidth={true}
            InputProps={{
              style: { fontSize: 14 },
              startAdornment:
                icon && iconPosition === "start" ? (
                  <InputAdornment position={iconPosition}>
                    <IconButton onClick={onSearch}>
                      <Icon size="14px" name={icon} type={iconType} />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  " "
                ),
              endAdornment:
                icon && iconPosition === "end" ? (
                  <InputAdornment position={iconPosition}>
                    <IconButton onClick={onSearch}>
                      <Icon size="14px" name={icon} type={iconType} />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  " "
                ),
            }}
          />
        </>
      )} */}
    </Fragment>
  );
};
