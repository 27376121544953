import { ProfileState } from "../constants";
import { RootState } from "types";

export const isProfileStarred = (state: RootState): boolean | undefined => {
  return state.storeData.profile?.isStarred;
};

export const getProfileState = (state: RootState): ProfileState => {
  return state.storeData.profile?.state ?? ProfileState.match;
};
