import { SET_PROFILE_STATE, SET_STARRED_PROFILE, SET_STORE_PROFILE_DATA } from "../constants";
import produce from "immer";
import { IStoreDataState } from "types";

export const initStoreDataState: IStoreDataState = {
  profile: {
    username: undefined,
    lastSearch: undefined,
    isStarred: undefined,
    state: undefined,
  },
};

export const storeDataReducer = produce((draft, action) => {
  if (action.type === SET_STORE_PROFILE_DATA) {
    draft.profile = action.payload;
  } else if (action.type === SET_STARRED_PROFILE) {
    draft.profile.isStarred = action.payload.isStarred;
  } else if (action.type === SET_PROFILE_STATE) {
    draft.profile.state = action.payload.state;
  }
}, initStoreDataState);