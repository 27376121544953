import { Organization, Profile, ThesisDto } from "types";
import { 
  SET_DSPACE_THESES_ERROR,
  SET_DSPACE_THESES_INIT,
  SET_DSPACE_THESES_REQUESTING,
  SET_DSPACE_THESES_SUCCESS,
  SET_ORGANIZATIONS_ERROR,
  SET_ORGANIZATIONS_INIT,
  SET_ORGANIZATIONS_REQUESTING,
  SET_ORGANIZATIONS_SUCCESS,
  SET_REPOSITORIES_DATA_ERROR,
  SET_REPOSITORIES_DATA_INI,
  SET_REPOSITORIES_DATA_REQUESTING,
  SET_REPOSITORIES_DATA_SUCCESS,
  SET_SOCIAL_NETWORKS_ERROR,
  SET_SOCIAL_NETWORKS_INIT,
  SET_SOCIAL_NETWORKS_REQUESTING,
  SET_SOCIAL_NETWORKS_SUCCESS,
  SET_STARRED_REPOS_ERROR,
  SET_STARRED_REPOS_INIT,
  SET_STARRED_REPOS_REQUESTING,
  SET_STARRED_REPOS_SUCCESS,
  SET_USER_DETAIL_REQUESTING,
  SET_USER_PROFILE_ERROR,
  SET_USER_PROFILE_INIT,
  SET_USER_PROFILE_REQUESTING,
  SET_USER_PROFILE_SUCCESS
} from "../constants";

export const createSetUserDetailRequestingAction = (username?: string, firstname?: string, lastname?: string) => ({
  type: SET_USER_DETAIL_REQUESTING,
  payload: {
    username,
    firstname,
    lastname,
  },
});

export const createSetUserDetailInitAction = () => ({
  type: SET_USER_PROFILE_INIT,
});

export const createSetUserProfileInitAction = () => ({
  type: SET_USER_PROFILE_INIT,
});

export const createSetUserProfileRequestingAction = () => ({
  type: SET_USER_PROFILE_REQUESTING,
});

export const createSetUserProfileSuccessAction = (userProfile: Profile) => ({
  type: SET_USER_PROFILE_SUCCESS,
  payload: {
    userProfile,
  },
});

export const createSetUserProfileErrorAction = (errorMsg: string) => ({
  type: SET_USER_PROFILE_ERROR,
  payload: {
    errorMsg,
  },
});

export const createSetOrganizationsInitAction = () => ({
  type: SET_ORGANIZATIONS_INIT,
});

export const createSetOrganizationsRequestingAction = () => ({
  type: SET_ORGANIZATIONS_REQUESTING,
});

export const createSetOrganizationsSuccessAction = (organizations: Organization[]) => ({
  type: SET_ORGANIZATIONS_SUCCESS,
  payload: {
    organizations,
  },
});

export const createSetOrganizationsErrorAction = (errorMsg: string) => ({
  type: SET_ORGANIZATIONS_ERROR,
  payload: {
    errorMsg,
  },
});

export const createSetRepositoriesDataInitAction = () => ({
  type: SET_REPOSITORIES_DATA_INI,
});

export const createSetRepositoriesDataRequestingAction = () => ({
  type: SET_REPOSITORIES_DATA_REQUESTING,
});

export const createSetRepositoriesDataSuccessAction = (data: any) => ({
  type: SET_REPOSITORIES_DATA_SUCCESS,
  payload: {
    emails: data.emails,
    languages: data.languages,
    reposInfo: data.reposInfo,
    roles: data.roles,
  },
});

export const createSetRepositoriesDataErrorAction = (errorMsg: string) => ({
  type: SET_REPOSITORIES_DATA_ERROR,
  payload: {
    errorMsg,
  },
});

export const createStarredReposInitAction = () => ({
  type: SET_STARRED_REPOS_INIT,
});

export const createStarredReposRequestingAction = () => ({
  type: SET_STARRED_REPOS_REQUESTING,
});

export const createStarredReposSuccessAction = (starredRepos: any) => ({
  type: SET_STARRED_REPOS_SUCCESS,
  payload: {
    starredRepos,
  },
});

export const createStarredReposErrorAction = (errorMsg: string) => ({
  type: SET_STARRED_REPOS_ERROR,
  payload: {
    errorMsg,
  },
});

export const createSocialNetworksInitAction = () => ({
  type: SET_SOCIAL_NETWORKS_INIT,
});

export const createSocialNetworksRequestingAction = () => ({
  type: SET_SOCIAL_NETWORKS_REQUESTING,
});

export const createSocialNetworksSuccessAction = (socialNetworkUrls: string[]) => ({
  type: SET_SOCIAL_NETWORKS_SUCCESS,
  payload: {
    socialNetworkUrls,
  },
});

export const createSocialNetworksErrorAction = (errorMsg: string) => ({
  type: SET_SOCIAL_NETWORKS_ERROR,
  payload: {
    errorMsg,
  },
});

export const createDspaceThesesInitAction = () => ({
  type: SET_DSPACE_THESES_INIT,
});

export const createDspaceThesesRequestingAction = () => ({
  type: SET_DSPACE_THESES_REQUESTING,
});

export const createDspaceThesesSuccessAction = (dSpaceTheses: ThesisDto[]) => ({
  type: SET_DSPACE_THESES_SUCCESS,
  payload: {
    dSpaceTheses,
  },
});

export const createDspaceThesesErrorAction = (errorMsg: string) => ({
  type: SET_DSPACE_THESES_ERROR,
  payload: {
    errorMsg,
  },
});
