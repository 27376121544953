import { styled } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components';
import { TextInput } from '../../components/TextInput';
import { Text } from "../../components/Text";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardKeyCodes, useKeyboadListener, useProfileNotFound } from 'hooks';
import { getUserDetailStatus } from 'selectors';
import { Status } from 'types';
import { FullscreenWrapper } from 'components/FullscreenWrapper';
import { Loader } from 'components/Loader';
import { Path } from '../../constants';
import { createSetUserDetailRequestingAction } from 'actions';

const StyledContainer = styled('div')({
    height: "100vh",
    width: "100vw",
    display: "grid",
    gridTemplateColumns: "210px auto 210px",
    gridTemplateRows: "110px auto 110px",
    alignItems: "center",
    justifyItems: "center",
    backgroundPosition: "center",
    background: "url(images/home-page-bg.png), linear-gradient(to right, #171E27, #0D2241)",
});

const StyledCard = styled('div')({
    gridRow: 2,
    gridColumn: 2,
    height: 420,
    width: 660,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    background: 'rgb(18 23 31 / 60%) 0% 0% no-repeat padding-box',
    backdropFilter: "blur(12px)",
});

const StyledContentWrapper = styled('div')({
    display: "flex",
    flexDirection: 'column',
    width: 440,
    justifyContent: "center",
    gap: 24,
    alignItems: "center",
});

const StyledImg = styled('img')({
    width: 140,
    height: 40,
});

const StyledLogo = styled('div')({
    display: "flex",
    alignItems: "start",
    width: "100%",
    height: "100%",
    justifyContent: "end",
    gridColumn: 1,
    gridRow: 3,
});

export const HomePage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userDetailStatus = useSelector(getUserDetailStatus);
    const [searchText, setSearchText] = useState("");
    const search = () => {
        if(searchText === "") {
            return;
        }
        dispatch(createSetUserDetailRequestingAction(searchText));
    };
    useKeyboadListener(search, KeyboardKeyCodes.enter);
    useProfileNotFound(Path.userDetailPage + `?username=${searchText}`);

    return (
        <StyledContainer>
            {
                userDetailStatus === Status.requesting 
                && <FullscreenWrapper>
                        <Loader variant="large" />
                    </FullscreenWrapper>
            }
            <StyledCard>
                <StyledContentWrapper>
                    <Text type="header" value={t("TypeTheNameOfProgrammer")}/>
                    <TextInput 
                        placeHolder="Jan Novák" 
                        value={searchText} 
                        onChange={(e: any) => setSearchText(e.currentTarget.value)}
                        
                    />
                    <Button backgroundColor="#4D84FF" width={"100%"} variant="contained" onClick={search}>{t("searchGithubProfile")}</Button>
                </StyledContentWrapper>
            </StyledCard>
            <StyledLogo>
                <StyledImg src="images/home-page-logo.svg" />
            </StyledLogo>
        </StyledContainer>
    );
};