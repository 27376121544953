import { ProfileState } from "../constants";
import { StoreProfile } from "types/storeData";

enum Profiles {
  objectName = "profiles",
  keyPath = "username",
  databaseName = "profiles",
  databaseVersion = 1,
}

export class IndexedDB {
  private static _db: IDBDatabase;
  
  public static init(): void {
    const request = window.indexedDB.open(Profiles.databaseName, Profiles.databaseVersion);
    this.addListerners(request);
  }

  public static async updateProfileState(username: string, state: ProfileState): Promise<StoreProfile> {
    return await this.update(Profiles.objectName, username, 'state', state);
  }

  public static async addOrUpdateSearchProfile(username: string): Promise<StoreProfile> {
    const profile = await this.get(Profiles.objectName, username);
    const now = (new Date()).getTime();

    if (profile) {
      return(await this.update(Profiles.objectName, username, 'lastSearch', { lastSearch: now }));
    } else {
      return(await this.add(Profiles.objectName, Profiles.keyPath, username, { lastSearch: now }));
    }
  }

  public static async addOrUpdateStarredProfile(username: string, isStarred: boolean): Promise<StoreProfile> {
    const profile = await this.get(Profiles.objectName, username);
    
    if (profile) {
      return(await this.update(Profiles.objectName, username, 'isStarred', isStarred));
    } else {
      return(await this.add(Profiles.objectName, Profiles.keyPath, username, { isStarred }));
    }
  }

  public static async getProfile(username: string): Promise<StoreProfile | undefined> {
    return new Promise((resolve) => { this.get(Profiles.objectName, username).then((value: any) => {
        if (value) return resolve(value as StoreProfile);
        return resolve(undefined);
      });
    });
  }

  public static async add(objectName: string, keyPath: string, key: string, data: Object): Promise<Object> {
    return new Promise((resolve) => {
      const objectStore = this._db
        .transaction(objectName, 'readwrite')
        .objectStore(objectName);
      const dataToStore = { [keyPath]: key, ...data };
      objectStore.add(dataToStore);
      return resolve(dataToStore);
    });
  }

  public static async update(objectName: string, key: string, propName: string, value: Object): Promise<Object> {
    return new Promise((resolve) => {
      const objectStore = this._db
      .transaction(objectName, 'readwrite')
      .objectStore(objectName);
    
      objectStore.openCursor().onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor && cursor.key === key) {
          const updatedData = cursor.value;
          updatedData[propName] = value;
          cursor.update(updatedData);
          return resolve(updatedData);
        }
        cursor.continue();
      }
    });
  }

  public static async get(objectName: string, key: string): Promise<Object> {
    return new Promise((resolve) => {
      const objectStore = this._db
       .transaction(objectName)
       .objectStore(objectName);

      const request = objectStore.get(key);
      request.onsuccess = (e: any) => {
        return resolve(e.target.result)
      }
    });
  }

  private static addListerners(request: IDBOpenDBRequest): void {
    request.onsuccess = (): void => {
      this._db = request.result;
    };

    request.onupgradeneeded = this.createObjectsStore;
  }

  private static createObjectsStore(e: any): void {
    this._db = e.target.result;
    this._db.createObjectStore(Profiles.objectName, { keyPath: Profiles.keyPath.toString() });
  }
}