import { styled } from '@mui/material/styles';
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const StyledContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 40,
    color: "#37404d",
    height: "100%",
    justifyContent: "center",
});

export type NoDataBannerProps = {
    width?: number,
    height?: number,
    src?: string,
}

export const NoDataBanner: FunctionComponent<NoDataBannerProps> = ({
    width = 116,
    height = 100,
}) => {
    const { t } = useTranslation();
    
    return(
        <StyledContainer>
            <img {...{ width, height }} src="images/no-data.svg" />
            {t("NoDataFound")}
        </StyledContainer>
    );
}