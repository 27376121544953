
import { Avatar, Stack, styled, Typography } from "@mui/material";
import { FunctionComponent } from "react";

const StyledStack = styled(Stack)({
  display: 'flex',
  alignItems: 'center',
});

const StyledTypography = styled(Typography)({
  opacity: 0.65,
});

export type OrganizationProps = {
  imageSrc: string,
  title: string,
  description: string,
}

export const Organization: FunctionComponent<OrganizationProps> = ({
  imageSrc,
  title,
  description,
}) => {
  return(
    <StyledStack direction="row" spacing={2}>
      <Avatar src={imageSrc} />
      <Stack>
        <Typography variant="subtitle1" fontSize="16px" fontWeight="300" gutterBottom>{title}</Typography>
        <StyledTypography variant="subtitle1" color="#E1E3E6" fontSize="14px" fontWeight="300" gutterBottom>{description}</StyledTypography>
      </Stack>
    </StyledStack>
  );
}
