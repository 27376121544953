import { Loader } from "components";
import { FunctionComponent } from "react";
import styles from './Text.module.scss';

type textProps = {
    type: "big" | "bigWithLabel" | "inline" | "header",
    label?: string,
    value?: string,
    isLoading?: boolean,
}

export const Text: FunctionComponent<textProps> = ({
    type,
    label,
    value = "",
    isLoading = false,
}) => {
    
    if(isLoading) {
      return (
        <div>
          <div className={styles.label}>{label}</div>
            <div className={styles.value}>
              <div className={styles.loaderWrapper}>
                <Loader variant="small" />
              </div>
            </div>
        </div>
      );
    }

    switch(type){
        case "big": {
            return (
                <div className={styles.value}>
                    {Array.isArray(value)
                        ? value[0].toString().concat(" ", "-", " ", value[1].toString()) 
                        : value
                    }
                </div>
            );
        }
        case "bigWithLabel": {
            return(
                <div>
                    <div className={styles.label}>{label}</div>
                    <div className={styles.value}>
                        {Array.isArray(value)
                             ? value[0].toString().concat(" ", "-", " ", value[1].toString()) 
                             : value
                        }
                    </div>
                </div>
            )
        }
        case "inline": {
            return(
                <div>
                    <label>{label}</label>
                    <label>{value}</label>
                </div>
            )
        }

        case "header": {
            return(
                <div className={styles.header}>{value}</div>
            )
        }
    }
}