import { styled } from "@material-ui/core";
import { Card, DocumentRow, StyledCardContent } from "components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDSpaceTheses, getDSpaceThesesStatus } from "selectors";
import { Status } from "types";

const StyledCard = styled(Card)({
  height: 200
});

export const DSpaceThesisCard = () => {
  const { t } = useTranslation();
  const dSpaceTheses = useSelector(getDSpaceTheses);
  const status = useSelector(getDSpaceThesesStatus);
  
  return (
    <StyledCard title={t("diplomaThesis")} hasData={!!dSpaceTheses?.length} isLoading={status === Status.requesting}>
      <StyledCardContent>
        {dSpaceTheses?.map(dSpace => 
          <DocumentRow 
            title={dSpace.title}
            year={dSpace.year}
            url={dSpace.url}
            key={dSpace.url}
            university={dSpace.university}
          />
        )}
      </StyledCardContent>
    </StyledCard>
  );
};
