export const SET_USER_DATA_INIT = "SET_USER_DATA_INIT";
export const SET_USER_DATA_REQUESTING = "SET_USER_DATA_REQUESTING";
export const SET_USER_DATA_SUCCESS = "SET_USER_DATA_SUCCESS";
export const SET_USER_DATA_ERROR = "SET_USER_DATA_ERROR";
export const SET_USER_DATA_NOT_FOUND = "SET_USER_DATA_NOT_FOUND";
export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';
export const SET_SEARCH_USER = "SET_SEARCH_USER";

export const SET_STORE_PROFILE_DATA = "SET_STORE_PROFILE_DATA";
export const ADD_STORE_PROFILE_DATA = "ADD_STORE_PROFILE_DATA";

export const SET_STARRED_PROFILE = "SET_STARRED_PROFILE";
export const ADD_STARRED_PROFILE = "ADD_STARRED_PROFILE";

export const SET_PROFILE_STATE = "SET_PROFILE_STATE";
export const ADD_PROFILE_STATE = "ADD_PROFILE_STATE";

export const ADD_SEARCH_PROFILE = "ADD_SEARCH_PROFILE";

export const SEARCH_USERS = "SEARCH_USERS";

export const SET_USER_DETAIL_INIT = "SET_USER_DETAIL_INIT";
export const SET_USER_DETAIL_REQUESTING = "SET_USER_DETAIL_REQUESTING";

export const SET_USER_PROFILE_INIT = "SET_USER_PROFILE_INIT";
export const SET_USER_PROFILE_REQUESTING = "SET_USER_PROFILE_REQUESTING";
export const SET_USER_PROFILE_SUCCESS = "SET_USER_PROFILE_SUCCESS";
export const SET_USER_PROFILE_ERROR = "SET_USER_PROFILE_ERROR";

export const SET_ORGANIZATIONS_INIT = "SET_ORGANIZATIONS_INIT";
export const SET_ORGANIZATIONS_REQUESTING = "SET_ORGANIZATIONS_REQUESTING";
export const SET_ORGANIZATIONS_SUCCESS = "SET_ORGANIZATIONS_SUCCESS";
export const SET_ORGANIZATIONS_ERROR = "SET_ORGANIZATIONS_ERROR";

export const SET_REPOSITORIES_DATA_INI = "SET_REPOSITORIES_DATA_INI";
export const SET_REPOSITORIES_DATA_REQUESTING = "SET_REPOSITORIES_DATA_REQUESTING";
export const SET_REPOSITORIES_DATA_SUCCESS = "SET_REPOSITORIES_DATA_SUCCESS";
export const SET_REPOSITORIES_DATA_ERROR = "SET_REPOSITORIES_DATA_ERROR";

export const SET_STARRED_REPOS_INIT = "SET_STARRED_REPOS_INIT";
export const SET_STARRED_REPOS_REQUESTING = "SET_STARRED_REPOS_REQUESTING";
export const SET_STARRED_REPOS_SUCCESS = "SET_STARRED_REPOS_SUCCESS";
export const SET_STARRED_REPOS_ERROR = "SET_STARRED_REPOS_ERROR";

export const SET_SOCIAL_NETWORKS_INIT = "SET_SOCIAL_NETWORKS_INIT";
export const SET_SOCIAL_NETWORKS_REQUESTING = "SET_SOCIAL_NETWORKS_REQUESTING";
export const SET_SOCIAL_NETWORKS_SUCCESS = "SET_SOCIAL_NETWORKS_SUCCESS";
export const SET_SOCIAL_NETWORKS_ERROR = "SET_SOCIAL_NETWORKS_ERROR";

export const SET_DSPACE_THESES_INIT = "SET_DSPACE_THESES_INIT";
export const SET_DSPACE_THESES_REQUESTING = "SET_DSPACE_THESES_REQUESTING";
export const SET_DSPACE_THESES_SUCCESS = "SET_DSPACE_THESES_SUCCESS";
export const SET_DSPACE_THESES_ERROR = "SET_DSPACE_THESES_ERROR";

export enum HttpStatus {
    ok = 200,
    notFound = 404,
    error = 500,
}

export enum Path {
    homePage = "/",
    userDetailPage = "/user-detail",
    profileNotFoundPage = "/profile-not-found",
}

export enum ApiPath {
  userProfile = "/api/crawler/profile",
  organizations = "/api/crawler/organizations",
  repositoriesData = "/api/crawler/repositories-data",
  starred = "/api/crawler/starred",
  socialNetwork = "/api/crawler/social-network-urls",
  dSpaceTheses = "/api/crawler/dspace-theses",
}

export enum ProfileState {
  match = 1,
  withoutReaction,
  unconcerned,
  interest,
}
