import ReactDOM from 'react-dom';
import { App } from './components/App';
import { runSagaMiddleware, store} from './store';
import { Provider } from "react-redux";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next"; 
import './index.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    color: {
      border: React.CSSProperties['color'];
      text: React.CSSProperties['color'];
    }
  }

  interface Palette {
    border?: Palette['primary'],
  }

  interface PaletteOptions {
    border?: Palette['primary'],
  }

  interface ThemeOptions {
    color: {
      border: React.CSSProperties['color'];
      text: React.CSSProperties['color'];
    }
  }
}
declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    border: true;
  }
}

const borderColor = "#5E666E"

const theme = createTheme({
  color: {
    border: borderColor,
    text: "#ffffff"
  },
  palette: {
    primary: {
      main: "#ffffff",
    },
    border: {
      main: borderColor,
      light: borderColor,
      dark: borderColor,
      contrastText: borderColor
    }
  }
});

runSagaMiddleware();
i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/translations.json",
    },
    load: "all",
    fallbackLng: "en",
  })
  .then(() => {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <App />
        </Provider>
      </ThemeProvider>,
      document.getElementById('root')
    );
  });