import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserDetailStatus } from "selectors";
import { Status } from "types";
import { Path } from "../constants"

export const useProfileNotFound = (onSuccessFoundPath?: string) => {
    const userDetailStatus = useSelector(getUserDetailStatus);
    const navigate = useNavigate();

    useEffect(() => {
        if(onSuccessFoundPath && userDetailStatus === Status.success) {
            navigate(onSuccessFoundPath);
        } else if(userDetailStatus === Status.notFound || userDetailStatus === Status.error) {
            navigate(Path.profileNotFoundPage);
        }
    }, [userDetailStatus, navigate, onSuccessFoundPath]);
}