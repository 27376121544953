import { Badge, Avatar as MUIAvatar, styled } from "@material-ui/core";
import { FC } from "react";

const StyledAvatar = styled(MUIAvatar)({
    width: 64,
    height: 64,
})

const StyledSmallAvatar = styled(MUIAvatar)({
    height: 26,
    width: 26,
});

const StyledLink = styled('a')({
  paddingBottom: 6
});

export type AvatarProps = {
    imageSrc?: string,
    link: string,
    badgeSrc?: string
}

export const Avatar: FC<AvatarProps> = ({
    imageSrc,
    link,
    badgeSrc,
}) => {
    return(
        <StyledLink href={link} target="_blank">
            {badgeSrc && <Badge
                overlap="circle"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                    <StyledSmallAvatar src={badgeSrc}/>
                }
            >
                <StyledAvatar src={imageSrc} />
            </Badge>}
            {!badgeSrc && <StyledAvatar src={imageSrc} />}
        </StyledLink>
    );
}