import { createStore as createReduxStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "../reducers";
import { rootSaga } from "../sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware();

export const store = createReduxStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
);

export const runSagaMiddleware = () => {
    sagaMiddleware.run(rootSaga);
}