import { AnyAction } from "redux"
import { put, takeEvery } from "redux-saga/effects"
import axios from "axios";
import { ApiPath, API_URL, HttpStatus, SET_USER_DETAIL_INIT, SET_USER_DETAIL_REQUESTING } from "../constants";
import { 
  createAddSearchProfile,
  createDspaceThesesErrorAction,
  createDspaceThesesInitAction,
  createDspaceThesesRequestingAction,
  createDspaceThesesSuccessAction,
  createSetOrganizationsErrorAction,
  createSetOrganizationsInitAction,
  createSetOrganizationsRequestingAction,
  createSetOrganizationsSuccessAction,
  createSetRepositoriesDataErrorAction,
  createSetRepositoriesDataInitAction,
  createSetRepositoriesDataRequestingAction,
  createSetRepositoriesDataSuccessAction,
  createSetUserProfileErrorAction,
  createSetUserProfileInitAction,
  createSetUserProfileRequestingAction,
  createSetUserProfileSuccessAction,
  createSocialNetworksErrorAction,
  createSocialNetworksInitAction,
  createSocialNetworksRequestingAction,
  createSocialNetworksSuccessAction,
  createStarredReposErrorAction,
  createStarredReposInitAction,
  createStarredReposRequestingAction,
  createStarredReposSuccessAction
} from "actions";

export const userDetailSaga = function* () {
  yield takeEvery(SET_USER_DETAIL_REQUESTING, setUserDetailRequesting);
  yield takeEvery(SET_USER_DETAIL_REQUESTING, getUserProfile);
  yield takeEvery(SET_USER_DETAIL_REQUESTING, getOrganizations);
  yield takeEvery(SET_USER_DETAIL_REQUESTING, getRepositoriesData);
  yield takeEvery(SET_USER_DETAIL_REQUESTING, getStarredRepos);
  yield takeEvery(SET_USER_DETAIL_REQUESTING, addSearchProfileToIndexedDb);
  yield takeEvery(SET_USER_DETAIL_INIT, setUserDetailInit);
}

const setUserDetailInit = function* () {
  yield put(createSetUserProfileInitAction());
  yield put(createSetOrganizationsInitAction());
  yield put(createSetRepositoriesDataInitAction());
  yield put(createStarredReposInitAction());
  yield put(createSocialNetworksInitAction());
  yield put(createDspaceThesesInitAction());
}

const setUserDetailRequesting = function* () {
  yield put(createSetUserProfileRequestingAction());
  yield put(createSetOrganizationsRequestingAction());
  yield put(createSetRepositoriesDataRequestingAction());
  yield put(createStarredReposRequestingAction());
  yield put(createSocialNetworksRequestingAction());
  yield put(createDspaceThesesRequestingAction());
}

const addSearchProfileToIndexedDb = function* (action: AnyAction): any {
  yield put(createAddSearchProfile(action.payload.username));
}

const getUserProfile = function* (action: AnyAction): any {
  const response = yield axios.post(API_URL + ApiPath.userProfile, {
    username: action.payload.username,
  });

  switch(response.status) {
    case HttpStatus.ok: {
      yield put(createSetUserProfileSuccessAction(response.data));
      const name = response.data?.name?.split(" ");
      if (name && name[0] && name[1]) {
        yield getSocialNetworks(name[0], name[1]);
        yield getDspaceTheses(name[0], name[1]);
      } else {
        yield put(createSocialNetworksErrorAction(response.data));
        yield put(createDspaceThesesErrorAction(response.data));
      }
      break;
    }

    case HttpStatus.error: {
      yield put(createSetUserProfileErrorAction(response.data));
      break;
    }
  }
}

const getOrganizations = function* (action: AnyAction): any {
  const response = yield axios.post(API_URL + ApiPath.organizations, {
    username: action.payload.username,
  });

  switch(response.status) {
    case HttpStatus.ok: {
      yield put(createSetOrganizationsSuccessAction(response.data));
      break;
    }
    case HttpStatus.error: {
      yield put(createSetOrganizationsErrorAction(response.errorMsg));
      break;
    }
  }
}

const getRepositoriesData = function* (action: AnyAction): any {
  const response = yield axios.post(API_URL + ApiPath.repositoriesData, {
    username: action.payload.username,
  });

  switch(response.status) {
    case HttpStatus.ok: {
      yield put(createSetRepositoriesDataSuccessAction(response.data));
      break;
    }
    case HttpStatus.error: {
      yield put(createSetRepositoriesDataErrorAction(response.data));
      break;
    }
  }
}

const getStarredRepos = function* (action: AnyAction): any {
  const response = yield axios.post(API_URL + ApiPath.starred, {
    username: action.payload.username,
  });

  switch(response.status) {
    case HttpStatus.ok: {
      yield put(createStarredReposSuccessAction(response.data));
      break;
    }
    case HttpStatus.error: {
      yield put(createStarredReposErrorAction(response.errorMsg));
      break;
    }
  }
}

const getSocialNetworks = function* (firstName: string, lastName: string): any {
  const response = yield axios.post(API_URL + ApiPath.socialNetwork, { firstName, lastName });

  switch (response.status) {
    case HttpStatus.ok: {
      yield put(createSocialNetworksSuccessAction(response.data));
      break;
    }
    case HttpStatus.error: {
      yield put(createSocialNetworksErrorAction(response.data));
      break;
    }
  }
}

const getDspaceTheses = function* (firstName: string, lastName: string): any {
  const response = yield axios.post(API_URL + ApiPath.dSpaceTheses, { firstName, lastName });

  switch (response.status) {
    case HttpStatus.ok: {
      yield put(createDspaceThesesSuccessAction(response.data));
      break;
    }
    case HttpStatus.error: {
      yield put(createDspaceThesesErrorAction(response.data));
      break;
    }
  }
}