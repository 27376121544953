import { styled } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Status } from 'types';
import { getRepositoriesDataStatus, getRoles } from '../../selectors';
import { Card } from '../Card';
import { PieChart } from '../PieChart';

const StyledContent = styled('div')({
  marginTop: -18,
});

const StyledCard = styled(Card)({
  height: 200,
});

export const RoleCard: FunctionComponent = () => {
  const { t } = useTranslation();
  const roles = useSelector(getRoles);
  const status = useSelector(getRepositoriesDataStatus);

  return(
    <StyledCard title={t("role")} hasData={!!roles?.length} isLoading={status === Status.requesting}>
      <StyledContent>
        <PieChart height={200} width={200} data={roles} dataKey="percentage" innerTextDataKey="name" legendDataKey="name" />
      </StyledContent>
    </StyledCard>
  );
}
