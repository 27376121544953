import { Bar, BarChart, ResponsiveContainer, XAxis } from "recharts";
import styles from './BarGraph.module.scss';

type BarGraph = {
    data: any,
    dataKey: string,
    height?: number,
    width?: number | string,
    xAxisDataKey?: string,
};

export const BarGraph: React.FC<BarGraph> = ({
    data,
    dataKey,
    height = 100,
    width = 100,
    xAxisDataKey
}) => (
    <ResponsiveContainer width={width} height={height}>
        <BarChart data={data} height={height}>
            {xAxisDataKey && <XAxis dataKey={xAxisDataKey} interval={0} tick={{ fontSize: 10 }} />}
            <Bar dataKey={dataKey} className={styles.bar} barSize={6} />
        </BarChart>
    </ResponsiveContainer>
);