import { createAddStarredProfileAction } from "actions";
import { Button } from "components/Button"
import { useDispatch, useSelector } from "react-redux";
import { getUsername, isProfileStarred } from "selectors";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { useTranslation } from "react-i18next";
import { styled } from "@material-ui/core";

const StyledStarIcon = styled(StarIcon)({
  color: '#4C83FF',
});

const StyledStarBorder = styled(StarBorderIcon)({
  color: '#4C83FF',
});

export const StarredButton = () => {
  const username = useSelector(getUsername);
  const isStarred = useSelector(isProfileStarred);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const addStarredProfile = () => {
    if (username) {
      dispatch(createAddStarredProfileAction(username, !isStarred));
    }
  }

  return(
    <Button 
      variant="contained"
      width="100px"
      backgroundColor="#12171F"
      borderColor="#2D3643"
      onClick={addStarredProfile}
      endIcon={isStarred ? <StyledStarIcon /> : <StyledStarBorder />}
    >{t("starred")}</Button>
  );
}
