import { RefObject, useEffect } from "react"

export enum KeyboardKeyCodes {
    enter = 13
}

export const useKeyboadListener = (onClick: (e: KeyboardEvent) => void, targetKey: KeyboardKeyCodes, targetElement?: RefObject<any>) => {
    useEffect(() => {
        const onClickHandler = (e: KeyboardEvent) => {
            if(targetKey === e.keyCode) {
                if(!targetElement || document.activeElement === targetElement.current) {
                    onClick(e);
                    return;
                }

                for(const element of targetElement?.current.children) {
                    if(element === document.activeElement) {
                        onClick(e);
                        break;
                    }
                }
            }
        }
        document.addEventListener('keydown', onClickHandler);

        return () => {
            document.removeEventListener('keydown', onClickHandler);
        };
            
    }, [onClick]);
}