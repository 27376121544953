import { styled } from "@material-ui/core";
import { Card, StyledCardContent, BarGraph } from "components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getRepositoriesDataStatus, getTopFiveLanguages } from "selectors";
import { Status } from "types";

const StyledCard = styled(Card)({
  height: 200
});

export const StackTop5Card = () => {
  const { t } = useTranslation();
  const languages = useSelector(getTopFiveLanguages);
  const status = useSelector(getRepositoriesDataStatus);

  return (
    <StyledCard title={t("stackTop5")} hasData={!!languages?.length} isLoading={status === Status.requesting}>
      <StyledCardContent>
        <BarGraph data={languages} dataKey="percent" xAxisDataKey="name" width="95%" height={180} />
      </StyledCardContent>
    </StyledCard>
  );
}