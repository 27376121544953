import { styled } from "@mui/material/styles"
import { FC } from "react"

const StyledDocumentRow = styled("a")({
    display: "inline-flex",
    fontSize: 14,
    width: "100%",
    borderBottom: "1px solid #2D3643",
    paddingBottom: 8,
    cursor: "pointer",
});

const StyledIcon = styled("img")({
    backgroundColor: "#4D84FF",
    height: 20,
    borderRadius: 40,
    marginRight: 8
});

const StyledYear = styled("label")({
    color: "#9EA3A8",
    cursor: "pointer",
    marginRight: 50,
});

const StyledContent = styled("div")({
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
});

const StyledUniversity = styled("label")({
    cursor: "pointer",
});

export type DocumentRowProps = {
    url: string,
    title?: string,
    year?: string | number,
    university?: string,
}

export const DocumentRow: FC<DocumentRowProps> = ({
    url,
    title,
    year,
    university
}) => {
    return(
        <StyledDocumentRow target="_blank" href={url}>
            <StyledIcon src="icons/document.svg" />
            <StyledContent>
                {title ?? url}
                <div>
                    {year && <StyledYear>{year}</StyledYear>}
                    {university && <StyledUniversity>{university}</StyledUniversity>}
                </div>
            </StyledContent>
        </StyledDocumentRow>
    )
}