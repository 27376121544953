import styled from '@emotion/styled';
import { FC } from 'react';
import { PieChart as RechartsPieChart, Pie, Cell, Label, Tooltip, Legend } from 'recharts';
import { round } from '../../utils';

const COLORS = ['#4c83ff', '#355AA7', '#1D2D4B', '#192130'];
const PIE_WEIGHT = 12;
const PADDING_ANGLE = 4;
const INNER_TEXT_OFFSET = 14;

export type PieChartProps = {
    width?: number,
    height?: number,
    data?: Array<any>,
    dataKey: string,
    innerTextDataKey?: string,
    legendDataKey?: string,
};

const StyledLegend = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
});

const StyledDot = styled("div")({
    height: 10,
    width: 10,
    borderRadius: 20,
});

const StyledLegendRow = styled("div")({
    display: "inline-flex",
    alignItems: "baseline",
    fontSize: 14,
    gap: 6,
    marginBottom: 16,
});

const StyledWrapper = styled("div")({
    display: "flex",
    justifyContent: "space-evenly",
    gap: 24,
});

export const PieChart: FC<PieChartProps> = ({
    width = 150,
    height = 150,
    data,
    dataKey,
    innerTextDataKey,
    legendDataKey
}) => {
  return (
        <StyledWrapper>
            {legendDataKey && <StyledLegend style={{height}}>
                {data?.map((data, index) => (
                    <StyledLegendRow>
                        <StyledDot style={{backgroundColor: COLORS[index]}}/>
                        {data[legendDataKey || ""]}
                    </StyledLegendRow>
                ))}
            </StyledLegend>}
            <RechartsPieChart height={height} width={width}>
                <Pie
                    isAnimationActive={false}
                    innerRadius={(height / 2) - PIE_WEIGHT}
                    outerRadius={(height / 2)}
                    paddingAngle={PADDING_ANGLE}
                    {...{ data, dataKey }}
                >            
                {innerTextDataKey && <Label content={
                    <>
                        <text x="50%" y={(height/2) - INNER_TEXT_OFFSET} fill="white" textAnchor="middle" dominantBaseline="central" fontSize="16px">
                            {data?.[0][innerTextDataKey]}
                        </text>
                        <text x="50%" y={(height/2) + INNER_TEXT_OFFSET} fill="white" textAnchor="middle" dominantBaseline="central" fontWeight="800" fontSize="36px">
                            {round(data?.[0][dataKey])}%
                        </text>
                    </>
                }/>}
                {data?.map((_value, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index] || "#1a1f28"} stroke="none" />
                ))}
                </Pie>
            </RechartsPieChart>
        </StyledWrapper>
    );
}