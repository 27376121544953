import { Profile, StoreProfile } from "../types";
import { Language, Organization, ReposInfo, RoleInfo, SocialNetwork, ThesisDto } from "./userData";

export enum Status {
    idle = "idle",
    requesting = "requesting",
    requestingMore = "requestingMore",
    error = "error",
    success = "success",
    change = "change",
    postSuccess = "postSuccess",
    notFound = "notFound",
}

interface BaseState {
  status: Status,
  errorMsg?: string,
}
export interface IUserProfileState extends BaseState {
  userProfile?: Profile,
};

export interface IOrganizationsState extends BaseState {
  organizations?: Organization[],
}

export interface IRepositoriesDataState extends BaseState {
  languages?: Language[],
  emails?: string[],
  reposInfo?: ReposInfo,
  roles?: RoleInfo[],
}

export interface IStarredReposState extends BaseState {
  starredRepos?: any, //TODO - roresit az bude ready be
}

export interface ISocialNetworkState extends BaseState {
  socialNetworks?: SocialNetwork[];
}

export interface IDSpaceThesesState extends BaseState {
  dSpaceTheses?: ThesisDto[];
}

export interface IUserDetailDataState {
  status: Status,
  userProfile: IUserProfileState,
  organizations: IOrganizationsState,
  repositoriesData: IRepositoriesDataState,
  starredRepos: IStarredReposState,
  socialNetworks: ISocialNetworkState,
  dSpaceTheses: IDSpaceThesesState,
}

export interface IStoreDataState {
  profile: StoreProfile,
}

export interface RootState {
    userDetailData: IUserDetailDataState,
    storeData: IStoreDataState,
}