import { FunctionComponent } from "react";
import { ProfilePanel } from "../../components/ProfilePanel";
import { Card } from '../../components/Card';
import { OverviewCard } from '../../components/OverviewCard';
import { useTranslation } from "react-i18next";
import { RoleCard } from '../../components/RoleCard';
import { useDispatch, useSelector } from "react-redux";
import { 
  getSocialNetworks,
  getUserDetailStatus,
  isProfileStarred 
} from "../../selectors";
import { SearchBar } from "components/SearchBar";
import { Avatar } from "components/Avatar";
import { useProfileNotFound } from "hooks";
import { Grid, styled } from "@material-ui/core";
import { DSpaceThesisCard, StackTop5Card, StateCard } from "components";
import { useEffect } from "react";
import { Status } from "types";
import { useSearchParams } from "react-router-dom";
import { createSetUserDetailRequestingAction } from "actions";

const StyledGrid = styled(Grid)({
  padding: "30px 100px",
  overflow: "hidden",
});

const StyledSocialNetworkCard = styled('div')({
  gap: 30,
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  alignItems: 'center',
});

const StyledCard = styled(Card)({
  height: 200
});

const StyledCardWrapperGrid = styled(Grid)({
  height: '100%',
});

const networkIcon: { [key: string]: any } = {
    LinkedIn: "icons/linkedIn.svg",
    Facebook: "icons/facebook.svg",
    Twitter: "icons/twitter.png",
};

export const UserDetailPage: FunctionComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const status = useSelector(getUserDetailStatus);
    const socialNetworks = useSelector(getSocialNetworks);
    const isStarred = useSelector(isProfileStarred);

    useEffect(() => {
      const username = searchParams.get("username");
      if (username && status === Status.idle) {
        dispatch(createSetUserDetailRequestingAction(username));
      }
    }, [status, searchParams, dispatch]);

    useProfileNotFound();
    
    return(
      <StyledGrid container spacing={2} justify="space-between">
        <Grid item container xs={12} justify="flex-end">
          <SearchBar />
        </Grid>
        <Grid item xs={12} sm={5} md={4} lg={3}>
          <ProfilePanel />
        </Grid>
        <StyledCardWrapperGrid item container xs={12} sm={7} md={8} lg={9} spacing={5}>
          {isStarred && <Grid item xs={12}>
            <StateCard />
          </Grid>}
          <Grid item xs={12} lg={6} xl={4}>
            <OverviewCard />
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            <RoleCard />
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            <StackTop5Card />
          </Grid>
          <Grid item xs={12} lg={6} xl={4}>
            <StyledCard title={t("socialNetworks")} hasData={!!socialNetworks?.length}>
                <StyledSocialNetworkCard>
                  {socialNetworks?.map(network =>
                    <Avatar imageSrc={networkIcon[network.name]} link={network.url} />
                  )}
                </StyledSocialNetworkCard>
            </StyledCard>
          </Grid>
          <Grid item xs={12} lg={12} xl={8}>
            <DSpaceThesisCard />
          </Grid>
        </StyledCardWrapperGrid> 
      </StyledGrid>
    );
}